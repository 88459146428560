import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import Icon from "../utils/Icons"
import {
  ThemeManagerContext,
  ThemeSetting,
} from "gatsby-styled-components-dark-mode"

const ThemeToggler = props => {
  const themeContext = useContext(ThemeManagerContext)
  // Setting system mode as default
  useEffect(() => themeContext.changeThemeSetting(ThemeSetting.DARK))
  return (
    <div>
      {/* <Toggler
        className="theme__toggler"
        title={`Change to ${themeContext.isDark ? "Light" : "Dark"} mode`}
        onClick={() => themeContext.toggleDark()}
      >
        {themeContext.isDark ? <Icon name="sun" /> : <Icon name="moon" />}
      </Toggler> */}
    </div>
  )
}

export default ThemeToggler

const Toggler = styled.button`
  padding: 12px;
  margin-right: 20px;
  width: 45px;
  height: 45px;
  @media (max-width: 768px) {
    display: none;
  }
`
