import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Icon from "../../utils/Icons"

import GlobalContext from "../../context/GlobalContext"

class ScrollTop extends Component {
  static contextType = GlobalContext
  scrollClick = e => {
    e.preventDefault()
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }
  render() {
    const scrollIndex = this.context.state.scroll
    // console.log(scrollIndex)
    return (
      <ScrollLink
        to="#"
        className={scrollIndex === "up" ? `show` : null}
        onClick={this.scrollClick}
      >
        <Icon name="arrow-up" />
      </ScrollLink>
    )
  }
}

const ScrollLink = styled(Link)`
  width: 45px;
  height: 45px;
  background: #000;
  color: #fff;
  padding: 8px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: fixed;
  z-index: 9;
  bottom: 20px;
  right: 15px;
  opacity: 0;
  transition: var(--transition);
  &.show {
    opacity: 1;
  }
`

export default ScrollTop
