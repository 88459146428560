import { createGlobalStyle } from "styled-components"
import recoleta from "../fonts/recoleta/typeface.css"
import "../fonts/made-dillan/typeface.css"
import "fontsource-dm-sans"
import rainBG from "../images/static/rain-bg.png"
// import rainBG from "../images/static/rain-bg-min.png"

export const GlobalStyle = createGlobalStyle`
  :root {
    --transition: 0.1s;
    --skyblue: rgb(234, 241, 246);
    --font-1: "DM Sans", Arial;
    /* --font-2: "DM Sans", Arial; */
    --font-2: "Recoleta", "DM Sans", -apple-system,BlinkMacSystemFont;
    /* --font-2: "MADE Dillan Regular", "DM Sans", -apple-system,BlinkMacSystemFont; */
  }
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* cursor: none; */
  }
  ::selection {
    background-color: ${props => props.theme.secondaryColor};
    color: ${props => props.theme.mainColor};
  }
  html {
    scroll-behavior: smooth;
  }
  html::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    -webkit-appearance: none;
  }
  html::-webkit-scrollbar-track {
    background-color: transprent;
  }
  html::-webkit-scrollbar-thumb {
    background-color: ${props =>
      props.theme.isDark ? props.theme.textColor : props.theme.secondaryColor};
    border: 1px solid ${props => props.theme.mainColor};
    border-radius: 1.5rem;
  }
  html::-webkit-scrollbar-thumb:hover {
    /* background-color: #272727; */
    background-color: ${props =>
      props.theme.isDark ? props.theme.secondaryColor : props.theme.textColor};
  }
  body {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-1);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: ${props =>
      props.theme.isDark ? props.theme.textColor : props.theme.secondaryColor};
    overflow-x: hidden !important;
    background-color: ${props => props.theme.mainColor};
    /* background-image: url(${rainBG}); */
    @media (max-width: 557px) {
      /* font-size: 14px; */
    }
    /* cursor: none;
    * {
      cursor: none;
    } */
  }
  p {
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 1.5;

  }
  img {
    display: block;
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color: currentColor;
  }
  li {
    list-style-type: none;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: inherit;
    color: inherit;
    background-color: inherit;
  }
  button:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
  button,
  input,
  select,
  textarea {
    color: inherit;
  }
  svg {
    display: block;
    max-width: 100%;
    /* width: 100%; */
    fill: currentColor;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: var(--font-2);
    /* font-family: ${recoleta}; */
    /* font-weight: 500; */
    font-weight: 700;
    letter-spacing: 0.5px;
    color: ${props =>
      props.theme.isDark ? props.theme.snowWhite : props.theme.secondaryColor};
  }
  textarea {
    resize: vertical;
  }
  section {
    margin-bottom: 100px;
    @media (max-width: 480px) {
      margin-bottom: 80px;
    }
  }
  .scrollable::-webkit-scrollbar {
    height: 6px;
    -webkit-appearance: none;
  }
  .scrollable::-webkit-scrollbar-track {
    /* border: 5px solid #fff; */
    background-color: #eeeeee;
    border-radius: 1.5rem;
  }
  .scrollable::-webkit-scrollbar-thumb {
    background-color: ${props =>
      props.theme.isDark ? props.theme.textColor : props.theme.secondaryColor};
    /* border: 1px solid #fff; */
    /* border: 1px solid ${props => props.theme.mainColor}; */
    border-radius: 1.5rem;
  }
  .scrollable::-webkit-scrollbar-thumb:hover {
    background-color: ${props =>
      props.theme.isDark ? props.theme.secondaryColor : props.theme.textColor};
  }
  /* Swiper resets */
  .swiper-container {
    overflow: visible;
    .swiper-slide {
      min-width: 300px;
    }
  }
  .swiper-container-horizontal > .swiper-scrollbar {
    bottom: auto;
    top: calc(100% + 1rem);
  }
  .swiper-scrollbar {
    height: 6px !important;
    background-color: ${props => props.theme.skinnyColor};
    border-radius: 1.5rem;
    @media (max-width: 992px) {
      height: 4px !important;
    }
    .swiper-scrollbar-drag {
      background-color: ${props =>
        props.theme.isDark
          ? props.theme.textColor
          : props.theme.secondaryColor};
      border-radius: 1.5rem;
      &:hover {
    background-color: ${props =>
      props.theme.isDark ? props.theme.secondaryColor : props.theme.textColor};
      }
    }
  }


  /* Page modal */
  .ReactModal__Overlay {
    z-index: 99;
  }
  .ReactModal__Overlay ,
  .ReactModal__Content {
    background-color: #000 !important;
    color: #fff !important;
  }
  .ReactModal__Content {
    transform: scale(.5);
    transform-origin: center;
    transition: .3s;
    &--after-open {
      transform: scale(1);
    }
  }

`
