import styled from "styled-components"

// (props) => {
//   let {radiusProp} = props;
//   let radius = 0;
//   switch (radiusProp) {
//     case "rounded":
//       radius = "3px";
//     case "circle":
//       radius = "50%";
//     default:
//       break;
//   }
// }

const ButtonIcon = styled.button`
  padding: 10px;
  width: 60px;
  height: 60px;

  /* ${props => (props.radius === "rounded" ? "" : "")} */
  /* border-radius :  ${({ radius }) =>
    radius === "circle" ? "50%" : "3px"} */
  svg {
    flex-shrink: 0;
  }
`
const MenuButton = styled(ButtonIcon)`
  @media (max-width: 557px) {
    margin: -10px -10px;
  }
`

export { ButtonIcon, MenuButton }
