import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion } from "framer-motion"
import GlobalContext from "../context/GlobalContext"

import Logo from "./partial/Logo"
import Menu from "./Menu"
import Icon from "../utils/Icons"
import { PrimaryButton } from "../styles/Button"
import { MenuButton } from "../styles/ButtonIcon"

import ThemeToggler from "../utils/ThemeToggler"

// Motion
const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] }

class Header extends Component {
  static contextType = GlobalContext

  render() {
    // const { siteTitle } = this.props
    const ctx = this.context
    const scrollIndex = ctx.state.scroll

    return (
      <>
        <HeaderInner
          className={`scrolling-${scrollIndex}`}
          initial={{ y: -30 }}
          animate={{
            y: 0,
            transition: { delay: 0.3 },
          }}
          transition={transition}
        >
          <Nav>
            <div className="nav__left">
              <MenuButton onClick={ctx.openMenu} radius="circle">
                <Icon name="menu-burger" />
              </MenuButton>
              <span>Menu</span>
            </div>
            <LogoWrapper className="logo-wrapper">
              <Link to="/">
                {/* <Icon name="logo" heigt="150" width="150" /> */}
                <Logo />
              </Link>
            </LogoWrapper>
            <div className="nav__right">
              <ThemeToggler />
              <PrimaryButton as={Link} to="#contact">
                <span>Say </span>Hi
              </PrimaryButton>
            </div>
          </Nav>
        </HeaderInner>
        {ctx.state.menuOpen && <Menu />}
      </>
    )
  }
}

export default Header

// Styles
const HeaderInner = styled(motion.header)`
  /* max-width: 1770px; */
  /* padding: 0px 15px; */
  /* z-index: 5; */
  position: relative;
  z-index: 99;
  &.scrolling-up {
    position: sticky;
    top: 0;
    backdrop-filter: blur(6px);
    .logo-wrapper {
      transform: translateY(20%) translateX(-50%);
      @media (max-width: 557px) {
        transform: translateY(26px) translateX(-50%);
      }
    }
  }
`
const Nav = styled(motion.nav)`
  /* background: ${props => props.theme.mainColor}; */
  color: ${props => props.theme.secondaryColor};
  border-bottom: 1px solid ${props =>
    props.theme.isDark ? props.theme.darkBorder : "transparent"};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  margin: 0 auto;
  /* transition: var(--transition); */
  @media (max-width: 557px) {
    padding: 10px 15px;
  }
  .nav__left {
    @media (max-width: 557px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
    span {
      margin-left: 5px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1.3px;
      font-size: 12px;
      @media (max-width: 557px) {
        font-size: 10px;
        font-weight: 500;
        margin: 0;
        letter-spacing: 4px;
      }
    }
  }
  .nav__left ,
  .nav__right {
    display: flex;
    align-items: center;
  }
  .nav__right {
    @media (max-width: 557px) {
      a {
        padding: 10px 18px;
        // span {
        //   display: none;
        // }
      }
    }
  }
`

const LogoWrapper = styled.div`
  width: 110px;
  transform: translateY(20%) translateX(-50%);
  position: absolute;
  left: 50%;
  @media (max-width: 557px) {
    width: 90px;
    transform: translateY(26px) translateX(-50%);
  }
  a {
    display: block;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
