import styled from "styled-components"

const Container = styled.div`
  max-width: 1230px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
`

export default Container
