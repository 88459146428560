import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import styled from "styled-components"
import Container from "../styles/Container"
// import Title from "../styles/Title"
// import TitleWrapper from "../styles/TitleWrapper"
// import NoiseBg from "../images/noises-dark.png"
import NoiseBg from "../images/noises-dark-v3.png"
import Icons from "../utils/Icons"

import { ExternalUrls } from "./partial/SocialLinks"

const Footer = () => {
  const { facebook, instagram, linkedin, whatsapp, github } = ExternalUrls

  // const data = useStaticQuery(graphql`
  //   query bioGraphy {
  //     contentfulBiography {
  //       title
  //       birthDate
  //     }
  //   }
  // `)
  // console.log(data)
  return (
    <FooterEl>
      <Container>
        {/* <TitleWrapper>
          <h1>
            <Title>Useful links :</Title>
          </h1>
        </TitleWrapper> */}
        <FooterMenu>
          <FooterMenuItem>
            <div className="title h3">Useful Links</div>
            <ul>
              <li>
                <Link to="/">Homepage</Link>
              </li>
              {/* <li>
                <Link to="/">About</Link>
              </li>
              <li>
                <Link to="/">Case studies</Link>
              </li>
              <li>
                <Link to="/">Latest Blog Posts</Link>
              </li> */}
            </ul>
          </FooterMenuItem>
          <FooterMenuItem>
            <div className="title h3">Get in touch</div>
            <ul>
              <li>
                <span>Email :</span>
                <a href="mailto:maeid.kh@gmail.com">maeid.kh@gmail.com</a>
              </li>
              <li>
                <span>Phone :</span>
                <a href="tel:+212696818851">+212 6 96 81 88 51</a>
              </li>
            </ul>
          </FooterMenuItem>
          <FooterMenuItem>
            <div className="title h3">Social Networks</div>
            <ul>
              <li>
                <a href={linkedin} target="_blank" rel="noreferrer">
                  Linkedin
                </a>
              </li>
              <li>
                <a href={github} target="_blank" rel="noreferrer">
                  Github
                </a>
              </li>
              <li>
                <a href={whatsapp} target="_blank" rel="noreferrer">
                  WhatsApp
                </a>
              </li>
              <li>
                <a href={instagram} target="_blank" rel="noreferrer">
                  Instagram
                </a>
              </li>
              <li>
                <a href={facebook} target="_blank" rel="noreferrer">
                  Facebook
                </a>
              </li>
            </ul>
          </FooterMenuItem>
          <FooterMenuItem className="newsletter">
            <div className="title h3">Newsletter</div>
            <p>Get occasional updates, case studies, blog posts and more.</p>
            <form>
              <input
                type="text"
                placeholder="Email..."
                className="form-control"
              />
              <button type="submit">
                <Icons name="arrow-right" />
              </button>
            </form>
          </FooterMenuItem>
        </FooterMenu>
        <div className="signature">
          © {new Date().getFullYear()}, Built with ❤ by <a href="#">Muhamed</a>
        </div>
      </Container>
    </FooterEl>
  )
}

export default Footer

const FooterEl = styled.footer`
  /* height: 50vh; */
  border-top: 1px solid
    ${props => (props.theme.isDark ? props.theme.darkBorder : "transparent")};
  padding: 5rem 0;
  background-color: #000;
  color: #fff;
  background-image: url(${NoiseBg});
  background-size: contain;
  animation: movePoz 300s linear infinite;
  opacity: 0.8;
  @media (max-width: 480px) {
    background-size: 125vw;
    animation: movePoz 80s linear infinite;
  }
  .signature {
    /* padding-top: 3rem; */
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.5px;
    a {
      text-decoration: underline;
    }
  }
  @keyframes movePoz {
    0% {
      background-position-x: 0;
    }
    /* 25% {
      background-position: right;
    }
    50% {
      background-position: top;
    }
    75% {
      background-position: bottom;
    } */
    100% {
      background-position-x: -300%;
    }
  }
`

const FooterMenu = styled.div`
  padding: 0 0 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 2rem;
`
const FooterMenuItem = styled.div`
  .title {
    font-size: 1.5rem;
    font-family: var(--font-2);
    /* font-weight: 600; */
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
  }
  p,
  li {
    font-weight: 300;
    line-height: 1.6;
    margin-top: 0.3rem;
    /* color: #acacac; */
    color: #cdcdcd;
    span {
      margin-right: 0.5rem;
    }
    a {
      display: inline-block;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  &.newsletter {
    p {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 1rem;
    }
    form {
      position: relative;
      input {
        background: transparent;
        color: #fff;
        width: 100%;
        padding: 10px 15px;
        border: 2px solid #acacac;
        outline: none;
        border-radius: 30px;
        height: 40px;
        letter-spacing: 0.5px;
        &:focus {
          border-color: currentColor;
          & ~ button {
            background-color: #000;
            transform: translateX(5px);
          }
        }
      }
      button {
        height: 40px;
        width: 40px;
        padding: 8px;
        line-height: 1;
        border: 2px solid #fff;
        border-radius: 100%;
        /* background: #000; */
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        right: 0px;
        top: 0px;
        transition: var(--transition);
        svg {
          width: 100%;
        }
      }
    }
  }
`
