import React, { Component } from "react"

const GlobalContext = React.createContext()

class GlobalProvider extends Component {
  // Context state
  constructor() {
    super()
    this.state = {
      theme: "default",
      menuOpen: false,
      scroll: "",
      isModalOpen: false,
    }
  }

  componentDidMount() {
    let scrollPos = 0
    window.addEventListener("scroll", () => {
      if (document.body.getBoundingClientRect().top > scrollPos) {
        this.setState({ scroll: "up" })
      } else {
        this.setState({ scroll: "down" })
      }
      scrollPos = document.body.getBoundingClientRect().top
    })

    document.addEventListener("keydown", this.escMenu, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escMenu, false)
  }

  // Method to update Menu state
  escMenu = () => {
    this.closeMenu()
  }

  // Method to update Menu state
  openMenu = () => {
    this.setState({ menuOpen: true })
  }
  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  // Method to update Modal state
  openModal = () => {
    // console.log("open modal")
    this.setState({ isModalOpen: true })
  }
  closeModal = () => {
    // console.log("close modal")
    this.setState({ isModalOpen: false })
  }

  render() {
    const { children } = this.props
    const { openMenu, closeMenu, openModal, closeModal } = this

    return (
      <GlobalContext.Provider
        value={{
          state: this.state,
          openMenu,
          closeMenu,
          openModal,
          closeModal,
        }}
      >
        {children}
      </GlobalContext.Provider>
    )
  }
}

export default GlobalContext

export { GlobalProvider }
