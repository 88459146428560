/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { withTheme } from "styled-components"
import { GlobalProvider } from "../context/GlobalContext"

import { motion, AnimatePresence } from "framer-motion"
import { GlobalStyle } from "../styles/GlobalStyle"
// Device detect
import { isMobile } from "react-device-detect"
import rainBG from "../images/static/rain-bg.png"

// import "../styles/layout.css"
import Header from "./header"
import Footer from "./Footer"
// import Cursor from "./partial/Cursor"
import ScrollTop from "./partial/ScrollTop"

const Layout = withTheme(props => {
  const { children, theme } = props
  const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // if (isMobile) {
  //   return (
  //     <ComingSoon>
  //       <span>We are coming soon ...</span>
  //     </ComingSoon>
  //   )
  // }
  return (
    <GlobalProvider>
      <GlobalStyle theme={theme} />
      {/* {!isMobile && <Cursor />} */}
      <Layout__main>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Footer />
        <ScrollTop />
      </Layout__main>
      {/* <AnimatePresence>
        <SplashScreen
        key="1"
          initial={{ height: 0 }}
          animate={{
            height: [0, window.innerHeight, 0],
            bottom: [null, 0, 0],
          }}
          exit={{
            height: [0, window.innerHeight, 0],
            top: [null, 0, 0],
          }}
          transition={{
            ...transition,
            delay: 0.4,
            duration: 1.3,
            times: [0, 0.5, 1],
          }}
        ></SplashScreen>
        <SplashScreen
          key="2"
          className="white"
          initial={{ height: 0 }}
          animate={{
            height: [0, window.innerHeight, 0],
            bottom: [null, 0, 0],
          }}
          exit={{
            height: [0, window.innerHeight, 0],
            top: [null, 0, 0],
          }}
          transition={{ ...transition, duration: 1.3, times: [0, 0.5, 1] }}
        ></SplashScreen>
      </AnimatePresence> */}
    </GlobalProvider>
  )
})

const Layout__main = styled.main`
  background-color: ${props => props.theme.mainColor};
  background-image: url(${rainBG});
`

const SplashScreen = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  left: 0;
  bottom: 100%;
  z-index: 9;
  &.white {
    background: ${props => props.theme.shade};
  }
`
const ComingSoon = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #ececec;
  color: #000;
  span {
    font-family: var(--font-2);
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
