import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

import GlobalContext from "../context/GlobalContext"

import Icon from "../utils/Icons"
import { MenuButton } from "../styles/ButtonIcon"
import TiltCard from "./partial/TiltCard"

const Menu = () => {
  const ctx = useContext(GlobalContext)
  const { closeMenu } = ctx

  const variants = {
    visible: { scale: 1 },
    hidden: { scale: 1.6 },
    exit: { scale: 1 },
  }

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])

  const redirectToView = async e => {
    let elem = document.querySelector(e.target.hash)
    e.preventDefault()
    // console.log(e.target.hash)
    closeMenu()
    elem.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <AnimatePresence>
      <FullMenu
        className={ctx.state.menuOpen && "open"}
        // initial="hidden"
        // animate="visible"
        // // exit="hidden"
        // variants={variants}
        // transition={{ type: "spring" }}
      >
        <FullMenuInner>
          <MenuButton className="menu__close" onClick={closeMenu}>
            <Icon name="cancel" />
          </MenuButton>
          <ul>
            <TiltCard as="li">
              <Link data-tilt to="#bio" onClick={redirectToView}>
                About me
              </Link>
            </TiltCard>
            <TiltCard as="li">
              <Link data-tilt to="#work" onClick={redirectToView}>
                My work
              </Link>
            </TiltCard>
            <TiltCard as="li">
              <Link data-tilt to="#skills" onClick={redirectToView}>
                Skills
              </Link>
            </TiltCard>
            <TiltCard as="li">
              <Link data-tilt to="#services" onClick={redirectToView}>
                Services
              </Link>
            </TiltCard>
            {/* <TiltCard as="li">
              <Link data-tilt to="#blog" onClick={redirectToView}>
                Blog
              </Link>
            </TiltCard> */}
            <TiltCard as="li">
              <Link data-tilt to="#contact" onClick={redirectToView}>
                Contact
              </Link>
            </TiltCard>
            {/* <li>
                  <Link data-tilt to="/" onClick={redirectToView}>
                    Tips & Tricks
                  </Link>
                </li>
                <li>
                  <Link data-tilt to="/" onClick={redirectToView}>
                    Ressources
                  </Link>
                </li> */}
          </ul>
        </FullMenuInner>
      </FullMenu>
    </AnimatePresence>
  )
}

const FullMenu = styled(motion.div)`
  position: fixed;
  z-index: -99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.97);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity, transform var(--transition);
  transform-origin: center;
  transform: scale(1.6);
  opacity: 0;
  /* Disabling open stat, to be conditionally rendered instead */
  z-index: 99;
  opacity: 1;
  transform: scale(1);
  &.open {
    z-index: 99;
    opacity: 1;
    transform: scale(1);
  }
  .menu__close {
    position: absolute;
    bottom: calc(100% + 20px);
    left: calc(100% + 15px);
    padding: 12px;
    color: #fff;
    @media (max-width: 767px) {
      left: auto;
      right: 0;
    }
  }
  a {
    font-size: 44px;
    font-weight: 700;
    display: block;
    padding: 15px 15px;
    letter-spacing: 2px;
    color: #c1c1c1;
    font-family: var(--font-2);
    transition: var(--transition);
    &:hover {
      color: #fff;
    }
    @media (max-width: 767px) {
      font-size: 36px;
      padding: 8px 15px;
    }
  }
`
const FullMenuInner = styled.div`
  position: relative;
  /* width: 40%; */
  /* background: #000; */
  /* height: 100%; */
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
`

export default Menu
