import styled from "styled-components"

const PrimaryButton = styled.button`
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  /* text-transform: uppercase; */
  text-align: center;
  letter-spacing: 0.5px;
  background: #000;
  color: #fff;
  padding: 14px 30px;
  border: 2px solid ${props => (props.theme.isDark ? "#ebebeb" : "#000")};
  border-radius: 30px;
  transition: color 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
  overflow: hidden;
  @media (max-width: 557px) {
    /* font-size: 14px; */
  }
  &::before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    transform: scale(1, 1);
    transform-origin: left center;
    z-index: -1;
    background: #000;
    transition: transform 0.45s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &:hover {
    /* color: #000; */
    /* &:before {
      transform: scale(0, 1);
      transform-origin: right center;
    } */
  }
  &:focus {
    outline: ${props =>
      props.theme.isDark ? "none" : "-webkit-focus-ring-color auto 1px"};
  }
`

export { PrimaryButton }
