import React from "react"
import styled from "styled-components"
import { isIOS, isSafari } from "react-device-detect"

export default function Logo() {
  return (
    <SVG viewBox="0 0 150 150" fill="none">
      <defs>
        <filter id="filter">
          {/* <!-- <feFlood floodColor="black" result="black" /> --> */}
          <feFlood result="flood1"></feFlood>
          <feFlood result="flood2"></feFlood>
          <feOffset in="SourceGraphic" dx="3" dy="0" result="off1a"></feOffset>
          <feOffset in="SourceGraphic" dx="2" dy="0" result="off1b"></feOffset>
          <feOffset in="SourceGraphic" dx="-3" dy="0" result="off2a"></feOffset>
          <feOffset in="SourceGraphic" dx="-2" dy="0" result="off2b"></feOffset>
          <feComposite
            in="flood1"
            in2="off1a"
            operator="in"
            result="comp1"
          ></feComposite>
          <feComposite
            in="flood2"
            in2="off2a"
            operator="in"
            result="comp2"
          ></feComposite>

          <feMerge
            x="0"
            width="100%"
            result="merge1"
            y="75.0522px"
            height="24.0931px"
          >
            <feMergeNode in="black"></feMergeNode>
            <feMergeNode in="comp1"></feMergeNode>
            <feMergeNode in="off1b"></feMergeNode>

            <animate
              attributeName="y"
              id="y"
              dur="4s"
              values="104px; 104px; 30px; 105px; 30px; 2px; 2px; 50px; 40px; 105px; 105px; 20px; 6ßpx; 400px; 104px; 40px; 70px; 10px; 30px; 104px; 102px"
              keyTimes="0; 0.362; 0.368; 0.421; 0.440; 0.477; 0.518; 0.564; 0.593; 0.613; 0.644; 0.693; 0.721; 0.736; 0.772; 0.818; 0.844; 0.894; 0.925; 0.939; 1"
              repeatCount="indefinite"
            ></animate>

            <animate
              attributeName="height"
              id="h"
              dur="4s"
              values="10px; 0px; 10px; 30px; 50px; 0px; 10px; 0px; 0px; 0px; 10px; 50px; 40px; 0px; 0px; 0px; 40px; 30px; 10px; 0px; 50px"
              keyTimes="0; 0.362; 0.368; 0.421; 0.440; 0.477; 0.518; 0.564; 0.593; 0.613; 0.644; 0.693; 0.721; 0.736; 0.772; 0.818; 0.844; 0.894; 0.925; 0.939; 1"
              repeatCount="indefinite"
            ></animate>
          </feMerge>

          <feMerge
            x="0"
            width="100%"
            y="112.97px"
            height="3.04444px"
            result="merge2"
          >
            <feMergeNode in="black"></feMergeNode>
            <feMergeNode in="comp2"></feMergeNode>
            <feMergeNode in="off2b"></feMergeNode>

            <animate
              attributeName="y"
              id="y"
              dur="4s"
              values="103px; 104px; 69px; 53px; 42px; 104px; 78px; 89px; 96px; 400px; 67px; 50px; 96px; 66px; 88px; 42px; 13px; 350px; 100px; 204px;"
              keyTimes="0; 0.055; 0.100; 0.125; 0.159; 0.182; 0.202; 0.236; 0.268; 0.326; 0.357; 0.400; 0.408; 0.461; 0.493; 0.513; 0.548; 0.577; 0.613; 1"
              repeatCount="indefinite"
            ></animate>

            <animate
              attributeName="height"
              id="h"
              dur="4s"
              values="0px; 0px; 0px; 16px; 16px; 12px; 12px; 0px; 0px; 5px; 10px; 22px; 33px; 11px; 0px; 0px; 10px"
              keyTimes="0; 0.055; 0.100; 0.125; 0.159; 0.182; 0.202; 0.236; 0.268; 0.326; 0.357; 0.400; 0.408; 0.461; 0.493; 0.513;  1"
              repeatCount="indefinite"
            ></animate>
          </feMerge>

          <feMerge>
            <feMergeNode in="SourceGraphic"></feMergeNode>

            <feMergeNode in="merge1"></feMergeNode>
            <feMergeNode in="merge2"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <circle cx="75" cy="75" r="73" fill="#fff" strokeWidth="4" />
      <path d="M84.664 84.772l-.058-6.182c0-2.52-.215-4.287-.645-5.303-.43-1.016-.977-1.777-1.64-2.285-.665-.527-1.563-.791-2.696-.791-1.113 0-3.486.879-7.12 2.637-.487.293-.741.566-.76.82V89.43c0 1.972 1.054 3.105 3.163 3.398.918.137 1.377.361 1.377.674 0 .293-.097.459-.293.498H62.926c-.196-.04-.293-.176-.293-.41 0-.371.459-.625 1.377-.762 2.11-.293 3.164-1.426 3.164-3.398V74.84c0-3.067-1.895-4.6-5.684-4.6-1.387 0-3.31.82-5.771 2.461-.625.41-1.084.791-1.377 1.143V89.43c0 1.972 1.055 3.105 3.164 3.398.918.137 1.377.361 1.377.674 0 .293-.098.459-.293.498H45.523c-.195-.04-.293-.176-.293-.41 0-.371.46-.625 1.377-.762 2.11-.293 3.165-1.406 3.165-3.34V72.613c0-.82-.45-1.386-1.348-1.699a7.903 7.903 0 00-1.26-.322 18.16 18.16 0 01-1.084-.264c-.254-.078-.42-.146-.498-.205-.059-.078-.088-.225-.088-.44 0-.234.147-.43.44-.585l7.47-3.516c.235-.117.381-.176.44-.176.332 0 .498.235.498.703v4.864c.02.117.088.175.205.175.137 0 .459-.136.967-.41.527-.273 1.21-.654 2.05-1.142.84-.508 1.944-1.094 3.311-1.758a10.049 10.049 0 014.394-.996c2.481 0 4.258 1.064 5.333 3.193.41.86.78 1.23 1.113 1.113.332-.136.85-.38 1.553-.732 1.66-.82 3.34-1.621 5.039-2.402 1.699-.782 3.105-1.172 4.218-1.172 4.512 0 6.768 4.111 6.768 12.334l-.264 8.555v1.699c0 1.523.762 2.52 2.285 2.988.645.215 1.26.371 1.846.469.606.078.996.156 1.172.234.195.078.293.235.293.469 0 .234-.098.37-.293.41H80.211c-.195-.04-.293-.176-.293-.41 0-.371.459-.625 1.377-.762 2.09-.293 3.135-1.504 3.135-3.633.156-.976.234-2.45.234-4.423z" />
      <path d="M89.752 79.967c-.234 0-.352.478-.352 1.435v8.145c0 1.914 1.055 3.027 3.164 3.34.918.117 1.377.332 1.377.644 0 .313-.097.469-.293.469H80.582c-.195-.04-.293-.176-.293-.41 0-.352.459-.586 1.377-.703 2.11-.313 3.164-1.426 3.164-3.34V53.014c0-.82-.45-1.387-1.348-1.7a7.903 7.903 0 00-1.26-.322 18.16 18.16 0 01-1.083-.264c-.254-.078-.42-.146-.498-.205-.059-.078-.088-.224-.088-.439 0-.234.146-.43.44-.586l7.47-3.516c.234-.117.38-.175.44-.175.331 0 .497.234.497.703v29.238c.157.566.362.898.616.996.253.098.527.147.82.147.566 0 1.494-.44 2.783-1.319a22.957 22.957 0 003.545-2.988c1.055-1.113 1.582-1.875 1.582-2.285 0-.43-.185-.694-.556-.791a5.788 5.788 0 00-1.26-.235 9.876 9.876 0 01-1.23-.234c-.372-.117-.557-.38-.557-.791 0-.41.4-.615 1.2-.615h11.163c.683 0 1.025.224 1.025.674 0 .234-.224.42-.674.556-.449.137-1.318.352-2.607.645-1.289.273-2.314.713-3.076 1.318l-8.408 6.563c0 .254.185.566.556.937l12.041 12.686c.782.8 1.582 1.328 2.403 1.582l1.64.469c.274.078.41.214.41.41 0 .351-.156.527-.468.527H97.486c-.195 0-.293-.117-.293-.352 0-.351.245-.576.733-.673 1.465-.274 2.197-.772 2.197-1.494 0-.215-.146-.499-.44-.85a31.416 31.416 0 01-.82-1.025l-9.052-9.58c0-.02-.01-.04-.03-.06h-.029z" />
    </SVG>
  )
}

const SVG = styled.svg`
  /* filter: url(#filter); */
  feFlood {
    &[result="flood1"] {
      flood-color: ${props => (props.theme.isDark ? "#616161" : "#E8AA52")};
    }
    &[result="flood2"] {
      flood-color: ${props => (props.theme.isDark ? "#616161" : "#F2D060")};
    }
  }
  filter: grayscale(1);
  circle {
    stroke: ${props =>
      props.theme.isDark ? props.theme.textColor : props.theme.secondaryColor};
    fill: ${props => props.theme.mainColor};
  }
  path {
    fill: ${props => props.theme.secondaryColor};
    filter: ${isIOS || isSafari ? "none" : "url(#filter)"};
  }
  /* @media (prefers-reduced-motion: no-preference) {
    circle, 
    path {
      filter: url(#filter);
    }
  } */
`
