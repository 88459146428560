import React, { useEffect } from "react"

// import styled from "styled-components"

import { isMobile } from "react-device-detect"
import VanillaTilt from "vanilla-tilt"

// const TiltCall = () => {
//   VanillaTilt.init(document.querySelectorAll("[data-tilt]"))
// }

const TiltCard = props => {
  useEffect(() => {
    !isMobile && VanillaTilt.init(document.querySelectorAll("[data-tilt]"))
  }, [])
  return (
    <div {...props} data-tilt>
      {" "}
      {props.children}{" "}
    </div>
  )
}

// const TiltCardElem = styled.div`
//   background-color: red;
// `

export default TiltCard
